<template>
  <v-app>
    <div
      style="
        background-image: url(/media/anjungan/BG-1280x1024.jpg);
        height: 100%;
        background-size: cover;
      "
    >
      <!-- Header::Start -->
      <div class="curved-header sample1">
        <div class="row px-10">
          <div class="col-1 pt-10">
            <img
              style="
                max-height: 198px;
                min-height: 198px;
                position: relative;
                top: 60px;
                left: 11px;
              "
              :src="config.city_logo"
              alt="LOGO DESA"
            />
          </div>
          <div class="col text-center pt-10 px-19">
            <h1 style="font-size: 54px; color: #1dac74">
              ANJUNGAN DESA MANDIRI
            </h1>
            <span
              style="font-size: 25px; text-align: justify; color: #3b4c42"
              >{{ config.address }}</span
            >
          </div>
        </div>
      </div>
      <!-- Header::End -->

      <!-- Menu::Start -->
      <div class="container mt-4" style="overflow: scroll; max-height: 560px">
        <!-- Breadcrumb::Start -->
        <h1 style="font-size: 35px; color: #bbc15d; font-weight: 600">
          <span @click="$router.push('/anjungan-mandiri/layanan-mandiri')">
            Layanan Mandiri</span
          >
          <span style="color: white"> > </span>
          <span @click="$router.push('/anjungan-mandiri/surat-menyurat')">
            Surat Menyurat</span
          >
          <span style="color: white"> > </span>
          <span style="font-size: 44px; color: white"> Keterangan Pindah</span>
        </h1>
        <!-- Breadcrumb::End -->
        <div class="mt-auto" style="position: relative; top: 17px">
          <span style="font-size: 30px; color: white; font-weight: bold">
            Form Keterangan Pindah</span
          >
          <b-form @submit.stop.prevent="formOnsubmit">
            <label
            for="input-id_card_number"
            style="color: white; font-size: 22px; font-weight: 600"
            >NIK:</label
          >
          <b-form-group id="input-group-id_card_number">
            <b-form-input
              id="input-id_card_number"
              v-model="IdCardNumber"
              placeholder="NIK"
              size="lg"
              style="font-size: 25px"
            ></b-form-input>
            <small
              class="text-danger"
              style="
                font-size: 25px;
                font-weight: bolder;
                color: #bbc15d !important;
              "
              >{{ error.id_card_number }}</small
            >
          </b-form-group>
          <label
            for="input-family_number"
            style="color: white; font-size: 22px; font-weight: 600"
            >No. KK:</label
          >
          <b-form-group id="input-group-family_number">
            <b-form-input
              id="input-family_number"
              v-model="FamilyNumber"
              placeholder="No. KK"
              size="lg"
              style="font-size: 25px"
            ></b-form-input>
            <small
              class="text-danger"
              style="
                font-size: 25px;
                font-weight: bolder;
                color: #bbc15d !important;
              "
              >{{ error.family_number }}</small
            >
          </b-form-group>
          <label
            for="input-hamlet_id"
            style="color: white; font-size: 22px; font-weight: 600"
            >Pilih No. WA Ketua RW:</label
          >
          <b-form-group id="input-group-hamlet_id">
            <treeselect
              v-model="form.hamlet_id"
              :multiple="false"
              placeholder="Pilih No. WA Ketua RW"
              :options="hamlets"
              style="font-size: 25px"
            />
            <small
              class="text-danger"
              style="
                font-size: 25px;
                font-weight: bolder;
                color: #bbc15d !important;
              "
              >{{ error.hamlet_id }}</small
            >
          </b-form-group>
            <label
              for="input-transfer_type_id"
              style="color: white; font-size: 22px; font-weight: 600"
              >Jenis Pindah:</label
            >
            <b-form-group id="input-group-transfer_type_id">
              <treeselect
                v-model="form.transfer_type_id"
                :multiple="false"
                style="font-size: 25px"
                placeholder="Pilih Jenis Pindah"
                :options="transfer_types"
              />
              <small class="text-danger">{{ error.transfer_type_id }}</small>
            </b-form-group>
            <b-form-group id="input-group-destination">
              <label
                for="input-destination"
                style="color: white; font-size: 22px; font-weight: 600"
                >Alamat Tujuan Pindah:
              </label>
              <b-form-textarea
                id="input-destination"
                v-model="form.destination"
                style="font-size: 25px"
                placeholder="Alamat Tujuan Pindah"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.destination }}</small>
            </b-form-group>

            <b-form-group id="input-group-reason">
              <label for="input-reason" style="color: white; font-size: 22px; font-weight: 600"
                >Alasan Pindah:
              </label>
              <b-form-textarea
                id="input-reason"
                v-model="form.reason"
                style="font-size: 25px"
                placeholder="Alasan Pindah"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.reason }}</small>
            </b-form-group>

            <div class="row">
              <div class="col-sm-12">
                <b-button
                  variant="white"
                  class="text-primary"
                  block
                  size="lg"
                  @click="$bvModal.show('modal-family-member')"
                >
                  Pilih Anggota Keluarga yang Pindah
                </b-button>
              </div>
              <div class="col-sm-12 pt-2">
                <table class="table thead-light table-white">
                  <thead class="thead-light">
                    <tr>
                      <th>No</th>
                      <th>NIK</th>
                      <th>Nama Lengkap</th>
                      <th>SHDK</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tr v-for="(family, i) in familyCollection" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ family.id_card_number }}</td>
                    <td>{{ family.name }}</td>
                    <td>{{ family.shdk_name }}</td>
                    <td>
                      <b-button
                        class="ml-2"
                        variant="danger"
                        v-b-tooltip.hover
                        title="Hapus"
                        @click="deleteCitizen(i)"
                        size="sm"
                      >
                        <i class="fas fa-trash px-0"></i>
                      </b-button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            
<b-button
            type="submit"
            size="lg"
            variant="white"
            style="
              height: 58px;
              width: 125px;
              font-size: 23px;
              font-weight: 600;
            "
            >Simpan</b-button
          >
            <b-button
            type="button"
            class="ml-2"
            style="
              background: #bbc15d;
              border-color: #bbc15d;
              height: 58px;
              width: 125px;
              font-size: 23px;
              font-weight: 600;
            "
            size="lg"
            @click="$router.push('/anjungan-mandiri/layanan-mandiri')"
          >
            Batal
          </b-button>
          </b-form>
        </div>
      </div>
      <!-- Menu::End -->
      <!-- Footer::Start -->
      <div class="curved-footer sample2">
        <div class="d-flex justify-content-center">
          <h1 style="font-size: 100px; font-weight: 600; color: #1dac74">
            {{ time }}
          </h1>
        </div>
        <div class="d-flex justify-content-center">
          <span style="color: #3b4c42; font-size: 30px" class="text-uppercase"
            >selamat datang di website desa {{ config.village_name }}</span
          >
        </div>
      </div>
      <!-- Footer::End -->
    </div>
    <ModalFamilyMemberForm @successAddMember="successAddMember" :familyCollection="[]"/>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import ModalFamilyMemberForm from "@/view/components/general/ModalFamilyMemberForm.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    ModalFamilyMemberForm,
  },
  data() {
    return {
      config: {},
      interval: null,
      time: null,
      timeout: null,
      timeout2: null,
      form: {
        title: "Surat Keterangan Pindah",
        letter_type_id: 4,
      },
      error: {
        id_card_number: "",
        family_number: "",
        hamlet_id: "",
        transfer_type_id: "",
        destionation: "",
        reason: "",
        total_member: "",
      },
      modal2: false,
      hamlets: [],
      transfer_types: [],
      selected_family_form: {
        citizen_id: "",
        name: "",
        id_card_number: "",
        shdk_id: "",
        shdk_name: "",
      },
      citizenPurpose: "add",
      familyCollection: [],
      familyCollectionDeleted: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
    successAddMember(value){
        let selected_family = {...value}
        this.familyCollection.push(selected_family)
        this.$bvModal.hide('modal-family-member')
    },
    async getHamletHeadOption() {
      let response = await module.setTreeSelectHamletHead("hamlets");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.hamlets = response.data;
        this.hamlets.unshift({
          label: "Pilih No. WA Ketua Rw",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getTransferTypeOption() {
      let response = await module.setTreeSelect("transfer-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.transfer_types = response.data;
        this.transfer_types.unshift({
          label: "Pilih Jenis Pindah",
          id: "",
          isDisabled: true,
        });
      }
    },
    reset() {
      this.form.id_card_number = "";
      this.form.family_number = "";
      this.form.hamlet_id = "";
      //
      this.form.name = "";
      this.form.purpose = "";
      this.form.address = "";
      this.form.start_date = "";

      this.error.id_card_number = "";
      this.error.family_number = "";
      this.error.hamlet_id = "";
      //
      this.error.name = "";
      this.error.purpose = "";
      this.error.address = "";
      this.error.start_date = "";
      this.familyCollection = []
      this.form.selected_family = ""
    },
    async formOnsubmit() {
      this.form.total_member = this.familyCollection.length;
      this.form.selected_family = JSON.stringify(this.familyCollection);
      // Make Request
      let response = await module.submit(
        this.form,
        "transfer-certificate-no-auth"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.reset();
      }
    },
  async checkIdCardNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(
        this.form,
        "check-id-card-number"
      );
      // Check Response
      if (response.state == "error") {
        Swal.fire(
          "",
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.",
          "error"
        );
        this.error.id_card_number =
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.";
      } else {
        this.error.id_card_number = "";
      }
    },
    async checkFamilyNumber() {
      // Make Request
      let response = await module.checkIdCardNumber(
        this.form,
        `check-compatibility/${this.form.id_card_number}/${this.form.family_number}`
      );
      console.log("resp", response);
      // Check Response
      if (response.success == "") {
        this.error.family_number =
          "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda.";
        Swal.fire(
          "",
          "NIK dan No. KK anda tidak sesuai. Silahkan input sesuai NIK dan No. KK anda.",
          "error"
        );
      } else {
        this.error.family_number = "";
      }
    },
  },
  computed: {
    IdCardNumber: {
      get() {
        this.error.id_card_number = "";
        return this.form.id_card_number;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.form.id_card_number = val;
          this.checkIdCardNumber();
        }, 500);
      },
    },
    FamilyNumber: {
      get() {
        this.error.family_number = "";
        return this.form.family_number;
      },
      set(val) {
        if (this.timeout2) clearTimeout(this.timeout2);
        this.timeout2 = setTimeout(() => {
          this.form.family_number = val;
          this.checkFamilyNumber();
        }, 500);
      },
    },
  },
  mounted() {
    this.getSetting();
    this.getHamletHeadOption();
    this.getTransferTypeOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 172px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -4px;
  width: 100%;
}

.form-control-lg {
  height: 61px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}
</style>